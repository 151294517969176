import { withAuthenticator, SignOut } from "aws-amplify-react"
import "@aws-amplify/ui/dist/style.css"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}></div>
      <SignOut />
    </Layout>
  )
}
export default withAuthenticator(IndexPage)
